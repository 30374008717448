import React from 'react'
import { graphql } from 'gatsby'
import Layout from '../components/Layout'
import Meta from '../components/Meta'
import Img from 'gatsby-image'
import { Parallax } from 'react-scroll-parallax'
import { siteMetadata } from '../../gatsby-config'

import logo from '../../content/images/logo.svg'
import styles from '../scss/pricing-tab.module.scss'

const Cennik = ({ data }) => {
  return (
    <Layout>
      <Meta site={siteMetadata} title="Cennik" />
      <Parallax className="parallax-image" y={[10, 20]} tagOuter="figure">
        <Img fluid={data.file.childImageSharp.fluid} />
      </Parallax>
      <div className="container">
        <div className="row">
          <div className="col-12 my-5">
            <h1 className="text-center">Cennik</h1>
          </div>
          <div className="col-12 mb-5">
            <p>
              Poniżej przedstawiamy ceny jedynie{' '}
              <strong>wybranych usług księgowych</strong>, ponieważ do każdego
              klienta ofertę dopasowujemy <strong>indywidualnie </strong>
              ustalając zakres oraz koszt wybranych usług. Poza wymienionymi
              usługami biuro PROMO TAX oferuje szereg innych usług rachunkowych
              oraz kadrowo-płacowych.
            </p>
          </div>
          <div className="col-12">
            <div className={styles.PricingTable}>
              <h1>
                <img src={logo} alt="Logo promotax" />
                <span>Cennik usług biura rachunkowego</span>
              </h1>
              <ul>
                <li>
                  <span className={styles.PTImg} />
                  <span className={styles.PTCenter}>
                    Oferta w zakresie prowadzenia ksiąg handlowych
                  </span>
                  <span className={styles.PTRight}>od 700,00 zł netto</span>
                </li>
                <li>
                  <div className={styles.PTImg} />
                  <span className={styles.PTCenter}>
                    Oferta w zakresie prowadzenia książki przychodów i rozchodów
                  </span>
                  <span className={styles.PTRight}>od 200,00 zł netto</span>
                </li>
                <li>
                  <div className={styles.PTImg} />
                  <span className={styles.PTCenter}>
                    Sporządzanie rocznych rozliczeń podatku dochodowego od osób
                    fizycznych
                  </span>
                  <span className={styles.PTRight}>od 50,00 zł netto</span>
                </li>
                <li>
                  <div className={styles.PTImg} />
                  <span className={styles.PTCenter}>
                    Obsługa kadrowo – płacowa pracownika
                  </span>
                  <span className={styles.PTRight}>od 40,00 zł netto</span>
                </li>
                <li>
                  <div className={styles.PTImg} />
                  <span className={styles.PTCenter}>
                    Obsługa osoby zatrudnionej w ramach umowy cywilnoprawnej
                    wraz z rachunkiem
                  </span>
                  <span className={styles.PTRight}>od 20,00 zł netto</span>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
} 

export default Cennik

export const pageQuery = graphql`
  query {
    file(relativePath: { eq: "index/fot-5.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1920) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`
